import firebase from "firebase/app";
import "firebase/database";

export default class FirebaseManager {
    _snapshotToArray(snapshot) {
        const arr = [];
        if (snapshot.hasChildren()) {
            snapshot.forEach(function(childsnap) {
                arr.push(childsnap.val());
            });
        }
        return arr;
    }

    async fetchSentOrders() {
        const ref = firebase.database().ref("/sentOrders");

        const snapshot = await ref.once("value");
        const orders = this._snapshotToArray(snapshot);
        orders.forEach(order => {
            Object.keys(order.garments).forEach(garment => {
                order.garments[garment] = Object.values(order.garments[garment]);
            });
        });

        return orders;
    }

    async fetchOrders() {
        const ref = firebase.database().ref("/orders");
        const snapshot = await ref.once("value");
        const orders = {};
        snapshot.forEach(childsnap => {
            orders[childsnap.key] = this._snapshotToArray(childsnap);
        });
        return orders;
    }

    async fetchOrderCounts() {
        const orders = await this.fetchOrders();
        const counts = {};
        for (const key of Object.keys(orders)) {
            counts[key] = counts[key] || 0;
            const garmentOrders = orders[key];
            for (const garmentOrder of garmentOrders) {
                for (const sizeCount of Object.values(garmentOrder.sizes)) {
                    counts[key] += sizeCount;
                }
            }
        }
        return counts;
    }

    async sendOrder(order) {
        let message;
        if (!order.name) {
            message = "Du måste fylla i ditt namn.";
        }
        if (order.items.length === 0) {
            message = "Du har inte valt några kläder.";
        }
        if (!order.mail) {
            message = "Du måste fylla i din e-postadress.";
        }
        if (message) {
            throw new Error(message);
        }

        const clothes = order.items.reduce((acc, item) => {
            acc[item.name] = acc[item.name] || {
                date: firebase.database.ServerValue.TIMESTAMP,
                mail: order.mail,
                name: order.name,
                sizes: {}
            };
            const garment = acc[item.name];
            garment.sizes[item.size] = garment.sizes[item.size] || 0;
            garment.sizes[item.size]++;
            return acc;
        }, {});

        for (const key of Object.keys(clothes)) {
            const garment = clothes[key];
            const ref = firebase.database().ref(`orders/${key}`);
            await ref.push(garment);
        }
    }

    async markAsSent(garmentOrders) {
        const signOrder = {
            date: firebase.database.ServerValue.TIMESTAMP,
            garments: {}
        };
        for (const garment of Object.keys(garmentOrders)) {
            const ref = firebase.database().ref(`/orders/${garment}`);
            const garmentOrder = await ref.once("value");
            signOrder.garments[garment] = garmentOrder.val();
        }
        const newRef = firebase.database().ref("/sentOrders");
        await newRef.push(signOrder);
        for (const garment of Object.keys(garmentOrders)) {
            const ref = firebase.database().ref(`/orders/${garment}`);
            await ref.remove();
        }
    }

    signOut() {
        firebase.auth().signOut();
    }
}
