import React, { Component } from "react";
import "../scss/Cart.scss";

class Cart extends Component {
    render() {
        return (
            <ul className="cart">
                {this.props.items.map((item, index) => (
                    <li key={index}>
                        <div>
                            <p>{item.name}</p>
                            <p>{item.size}</p>
                        </div>
                        <button
                            type="button"
                            className="removeFromCart"
                            onClick={() => this.props.onRemoveFromCart(index)}
                        />
                    </li>
                ))}
            </ul>
        );
    }
}

export default Cart;
