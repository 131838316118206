import React, { Component } from "react";
import Collapsible from "react-collapsible";
import clothes from "../resources/clothes";

import "../scss/GarmentOrder.scss";

class GarmentOrder extends Component {
    render() {
        const { orders, name } = this.props;
        const garment = clothes.find(c => c.name === name);
        return (
            <div className="wrapper">
                <input type="checkbox" onChange={e => this.props.onSelectedChange(this.props.name, e.target.checked)} />
                <Collapsible trigger={<h3>{this.props.name} ({garment && garment.realName}) - {orders.reduce((total, order) => (total + Object.keys(order.sizes).reduce((t, size) => (t + order.sizes[size]), 0)), 0)}st</h3>}>
                    {orders.map((order, index) => {
                        return (
                            <div key={index} className="personOrder">
                                <div className="nameDate">
                                    <h4>{order.name}</h4>
                                    <p>{new Date(order.date).toISOString().slice(0, 10)}</p>
                                </div>
                                <p>
                                    {Object.keys(order.sizes).reduce((string, size) => {
                                        return `${string === "" ? "" : `${string}, `}${order.sizes[size]}st ${size}`;
                                    }, "")}
                                </p>
                            </div>
                        );
                    })}
                </Collapsible>
            </div>
        );
    }
}

export default GarmentOrder;
