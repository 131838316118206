import React, { Component } from "react";
import Sidebar from "./Sidebar";
import Gallery from "./Gallery";
import Orders from "./Orders";
import Navbar from "./Navbar";
import CartButton from "./CartButton";

import "../scss/App.scss";
import "antd/dist/antd.css";

import FirebaseManager from "../FirebaseManager";
import CLOTHES from "../resources/clothes";

import swal from "sweetalert";
import withSizes from "react-sizes";
import { Layout } from "antd";
const { Sider, Content } = Layout;

const ORDER = {
    items: [],
    name: "",
    mail: ""
};

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: CLOTHES,
            order: ORDER,
            viewOrders: props.isAdmin,
            sidebarCollapsed: true
        };
        this.firebase = new FirebaseManager();
    }

    componentDidMount() {
        this.fetchOrderCounts();
    }

    fetchOrderCounts() {
        this.firebase
            .fetchOrderCounts()
            .then(counts => {
                const items = [...this.state.items];
                Object.keys(counts).forEach(name => {
                    items.find(item => item.name === name).ordered = counts[name];
                });
                this.setState({ items });
            })
            .catch(e => {
                console.error("Error fetching orders", e);
            });
    }

    handleSendOrder() {
        const order = this.state.order;

        this.firebase
            .sendOrder(order)
            .then(() => {
                swal({
                    title: "🎉",
                    text: "Din beställning är lagd",
                    icon: "success"
                });
                order.name = "";
                order.mail = "";
                order.items = [];
                this.setState({ order });
                this.fetchOrderCounts();
            })
            .catch(error => {
                swal({
                    title: "Hoppsan",
                    text: error.toLocaleString(),
                    icon: "warning"
                });
            });
    }

    handleAddToCart(item) {
        const order = this.state.order;
        const items = [...order.items];
        items.unshift({
            name: item.name,
            size: item.selectedSize
        });
        order.items = items;
        this.setState({ order });
    }

    handleRemoveFromCart(index) {
        const order = this.state.order;
        const items = [...order.items];
        items.splice(index, 1);
        order.items = items;
        this.setState({ order });
    }

    handleSizeChange(item, size) {
        const items = [...this.state.items];
        items.find(i => i.name === item.name).selectedSize = size;
        this.setState({ items });
    }

    handleNameChange(name) {
        const order = this.state.order;
        order.name = name;
        this.setState({ order });
    }

    handleMailChange(mail) {
        const order = this.state.order;
        order.mail = mail;
        this.setState({ order });
    }

    handleSignOut() {
        this.firebase.signOut();
    }

    handleClothesClick() {
        this.setState({ viewOrders: false });
    }

    handleOrdersClick() {
        this.setState({ viewOrders: true });
    }

    handleToggleSidebar() {
        console.log(this.state.sidebarCollapsed);
        this.setState({ sidebarCollapsed: !this.state.sidebarCollapsed });
    }

    render() {
        const collapsed = (this.props.narrowWidth && this.state.sidebarCollapsed) || this.props.isAdmin;
        let content;
        if (this.state.viewOrders) {
            content = <Orders />;
        } else {
            content = (
                <Gallery
                    items={this.state.items}
                    onSizeChange={this.handleSizeChange.bind(this)}
                    onAddToCart={this.handleAddToCart.bind(this)}
                />
            );
        }
        let sidebar;
        if (!this.props.isAdmin) {
            sidebar = (
                <Sidebar
                    order={this.state.order}
                    onNameChange={this.handleNameChange.bind(this)}
                    onMailChange={this.handleMailChange.bind(this)}
                    onRemoveFromCart={this.handleRemoveFromCart.bind(this)}
                    onSendOrder={this.handleSendOrder.bind(this)}
                    onSignOut={this.handleSignOut.bind(this)}
                    collapsed={collapsed}
                />
            );
        }
        let mainContentMargin = "500px";
        if (this.props.narrowWidth) {
            mainContentMargin = "100%";    
        }
		if (collapsed) {
			mainContentMargin = "0";
		}
        return (
            <Layout>
                <Sider
                    width={this.props.narrowWidth ? "100%" : 500}
                    collapsedWidth={0}
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                    style={{
                        position: "fixed",
                        left: 0,
                        height: "100vh"
                    }}
                >
                    {sidebar}
                </Sider>
                <Layout className="main-content" style={{ marginLeft: mainContentMargin }}>
                    <Content>
                        <CartButton
                            onClick={this.handleToggleSidebar.bind(this)}
                            className={this.props.narrowWidth ? "" : "hidden"}
                            collapsed={collapsed}
                        />
                        <Navbar
                            onClothesClick={this.handleClothesClick.bind(this)}
                            onOrdersClick={this.handleOrdersClick.bind(this)}
                            page={this.state.viewOrders ? "orders" : "clothes"}
                        />
                        {content}
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

const mapSizesToProps = ({ width }) => ({
    narrowWidth: width < 800
});

export default withSizes(mapSizesToProps)(App);
