import React, { Component } from "react";
import "../scss/TextInput.scss";

class TextInput extends Component {
    handleChange(event) {
        this.props.onChange(event.target.value);
    }

    render() {
        return (
            <div className="input">
                <label htmlFor={this.props.name}>{this.props.name}</label>
                <input
                    id={this.props.name}
                    type="text"
                    value={this.props.value}
                    onChange={this.handleChange.bind(this)}
                />
            </div>
        );
    }
}

export default TextInput;
