import React, { Component } from "react";
import TextInput from "./TextInput";
import Cart from "./Cart";
import Button from "./Button";
import "../scss/Sidebar.scss";

class Sidebar extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="sidebar">
                    <div className="header">
                        <img
                            src="images/kilsok_logo_new_liggande.png"
                            alt="Kils OK logo"
                        />
                    </div>
                    <h1>
                        <i className="fas fa-shopping-basket" />
                        Klädbeställning
                    </h1>
                    <p>
                        Priserna är barn/vuxen och är ungefärliga, vid över
                        20st/plagg eller vid köp av helt set kan det
                        bli billigare. Kontakta styrelsen för information om rabatt för ungdomar.
                    </p>
                    <p>
                        Lägg till alla plagg du vill beställa genom att välja
                        storlek och klicka på "lägg till". Plaggen listas här
                        nedan och när du är nöjd, fyll i namn och epost och
                        klicka på "lägg beställning". OBS! Tänk på att vissa plagg finns i dammodell och att storlekarna skiljer sig helt från Unisex.
                    </p>
                    <p>
                        Beställningarna skickas till SIGN när/om det finns minst
                        5st av ett plagg (för vissa plagg gäller fler eller färre per beställning) eller vid bestämt datum. Vid frågor kontakta Sanna Skoog eller Ylva Olm.
                    </p>
                    <TextInput
                        name="Namn"
                        value={this.props.order.name}
                        onChange={this.props.onNameChange}
                    />
                    <TextInput
                        name="E-post"
                        value={this.props.order.mail}
                        onChange={this.props.onMailChange}
                    />
                    <Cart
                        items={this.props.order.items}
                        onRemoveFromCart={this.props.onRemoveFromCart}
                    />
                    <Button
                        title="Lägg beställning"
                        onClick={this.props.onSendOrder}
                    />
                    <div className="signOut">
                        <button type="button" onClick={this.props.onSignOut}>
                            Logga ut
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Sidebar;
