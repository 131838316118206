export default [
    {
        name: "T-shirt",
		realName: "Basic o-shirt",
        ordered: 0,
        imageURL: "images/basic-shirt.png",
        price: "249 / 299",
        selectedSize: "M",
        sizes: ["116", "122", "128", "134", "140", "146", "DAM 2XS", "DAM XS", "DAM S", "DAM M", "DAM L", "DAM XL", "DAM 2XL", "DAM 3XL", "DAM 4XL", "2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"]
    },
    {
        name: "T-shirt nättyg",
		realName: "Ultra o-shirt",
        ordered: 0,
        imageURL: "images/ultra-shirt.png",
        price: 289,
        selectedSize: "M",
        sizes: ["DAM 2XS", "DAM XS", "DAM S", "DAM M", "DAM L", "DAM XL", "DAM 2XL", "DAM 3XL", "DAM 4XL", "2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"]
    },
    {
        name: "T-shirt nättyg (vit)",
		realName: "Ultra o-shirt (vit)",
        ordered: 0,
        imageURL: "images/ultra-shirt-white.png",
        price: 289,
        selectedSize: "M",
        sizes: ["DAM 2XS", "DAM XS", "DAM S", "DAM M", "DAM L", "DAM XL", "DAM 2XL", "DAM 3XL", "DAM 4XL", "2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"]
    },
    {
        name: "Linne",
		realName: "Flight singlet",
        ordered: 0,
        imageURL: "images/race-singlet.png",
        price: 249,
        selectedSize: "M",
        sizes: ["DAM 2XS", "DAM XS", "DAM S", "DAM M", "DAM L", "DAM XL", "DAM 2XL", "DAM 3XL", "2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL"]
    },
    {
        name: "Långärmad t-shirt",
		realName: "Basic long sleeve shirt",
        ordered: 0,
        imageURL: "images/long-shirt.png",
        price: 349,
        selectedSize: "M",
        sizes: ["DAM 2XS", "DAM XS", "DAM S", "DAM M", "DAM L", "DAM XL", "DAM 2XL", "DAM 3XL", "DAM 4XL", "2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"]
    },
    {
        name: "Långa nylonbyxor",
		realName: "Long o-pants",
        ordered: 0,
        imageURL: "images/long-opants.png",
        price: 289,
        selectedSize: "M",
        sizes: ["128", "134", "140", "2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL"]
    },
    {
        name: "Knälånga nylonbyxor",
		realName: "Race O-pants",
        ordered: 0,
        imageURL: "images/half-opants.png",
        price: 249,
        selectedSize: "M",
        sizes: ["128", "134", "140", "XXS", "XS", "S", "M", "L", "XL", "2XL", "3XL"]
    },
	{
		name: "Långa tights",
		realName: "Tights long",
		ordered: 0,
		imageURL: "images/long-tights.png",
		price: 319,
		selectedSize: "M",
		sizes: ["2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL"]
	},
    {
        name: "Korta tights",
		realName: "Tights low",
        ordered: 0,
        imageURL: "images/short-tights.png",
        price: 269,
        selectedSize: "M",
        sizes: ["2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL"]
    },
    {
        name: "Överdragsjacka",
		realName: "Track suit jacket",
        ordered: 0,
        imageURL: "images/jacket.png",
        price: "590 / 690",
        selectedSize: "M",
        sizes: ["130", "140", "150", "2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL"]
    },
    {
        name: "Överdragsbyxor",
		realName: "Track suit pants",
        ordered: 0,
        imageURL: "images/pants.png",
        price: "390 / 490",
        selectedSize: "M",
        sizes: ["130", "140", "150", "2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL"]
    },
    {
        name: "Överdragsjacka vinter",
		realName: "Winter suit jacket",
        ordered: 0,
        imageURL: "images/jacket.png",
        price: "890",
        selectedSize: "M",
        sizes: ["130", "140", "150", "2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL"]
    },
    {
        name: "Överdragsbyxor vinter",
		realName: "Winter suit pants",
        ordered: 0,
        imageURL: "images/pants.png",
        price: "720",
        selectedSize: "M",
        sizes: ["130", "140", "150", "2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL"]
    },
    {
        name: "Väst",
		realName: "Vest",
        ordered: 0,
        imageURL: "images/vest.png",
        price: 490,
        selectedSize: "M",
        sizes: ["130", "140", "150", "2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL"]
    },
    {
        name: "Löparjacka",
		realName: "Run protect jacket",
        ordered: 0,
        imageURL: "images/run-jacket.png",
        price: 790,
        selectedSize: "M",
        sizes: ["2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL"]
    },
    {
        name: "Löpartröja",
		realName: "Fly shirt",
        ordered: 0,
        imageURL: "images/run-shirt.png",
        price: 319,
        selectedSize: "M",
        sizes: ["DAM 2XS", "DAM XS", "DAM S", "DAM M", "DAM L", "DAM XL", "DAM 2XL", "DAM 3XL", "2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL"]
    },
    {
        name: "Skiddräkt tröja",
		realName: "Race Ski shirt",
        ordered: 0,
        imageURL: "images/ski-shirt.png",
        price: 590,
        selectedSize: "M",
        sizes: ["2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL"]
    },
    {
        name: "Skiddräkt byxor",
		realName: "Race ski pants",
        ordered: 0,
        imageURL: "images/ski-pants.png",
        price: 490,
        selectedSize: "M",
        sizes: ["2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL"]
    },
    {
        name: "Cykeljersy",
		realName: "Jersy basic",
        ordered: 0,
        imageURL: "images/cycle-shirt.png",
        price: 450,
        selectedSize: "M",
        sizes: ["2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL"]
    },
    {
        name: "Cykelbyxor",
		realName: "BIB shorts basic",
        ordered: 0,
        imageURL: "images/bib-shorts.png",
        price: 450,
        selectedSize: "M",
        sizes: ["2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL"]
    },
	{
		name: "OL-strumpor",
		realName: "O-socks PRO",
		ordered: 0,
		imageURL: "images/o-socks.png",
		price: 179,
		selectedSize: "38-40",
		sizes: ["30-33", "34-37", "38-40", "41-43", "44-46"]
	},
    {
        name: "Pannband 7cm",
		realName: "Headband PRO 7cm",
        ordered: 0,
        imageURL: "images/headband-7.png",
        price: 75,
        selectedSize: "M",
        sizes: ["S", "M", "L"]
    },
    {
        name: "Pannband 4cm",
		realName: "Headband PRO 4cm",
        ordered: 0,
        imageURL: "images/headband-4.png",
        price: 45,
        selectedSize: "M",
        sizes: ["S", "M", "L"]
    },
	{
		name: "Pannband 11cm i microfleece",
		realName: "Headband Wide",
		ordered: 0,
		imageURL: "images/headband-wide.png",
		price: 99,
		selectedSize: "58",
		sizes: ["56", "58", "60"]
	},
    {
        name: "Buff",
		realName: "Tube",
        ordered: 0,
        imageURL: "images/tube.png",
        price: 65,
        selectedSize: "One size",
        sizes: ["One size"]
    },
    {
        name: "Mössa",
		realName: "Hat S3",
        ordered: 0,
        imageURL: "images/pro-hat.png",
        price: 149,
        selectedSize: "58",
        sizes: ["56", "58", "60"]
    },
];
