import React, { Component } from "react";
import Button from "./Button";

import "../scss/Navbar.scss";

class Navbar extends Component {
    render() {
        return (
            <div className="navbar">
                <Button title="Kläder" onClick={this.props.onClothesClick} selected={this.props.page === "clothes"} />
                <Button
                    title="Beställningar"
                    onClick={this.props.onOrdersClick}
                    selected={this.props.page === "orders"}
                />
            </div>
        );
    }
}

export default Navbar;
