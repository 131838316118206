import React, { Component } from "react";
import Garment from "./Garment";
import "../scss/Gallery.scss";

class Gallery extends Component {
    render() {
        return (
            <div className="galleryContainer">
                {this.props.items.map(i => (
                    <Garment
                        item={i}
                        key={i.name}
                        onSizeChange={this.props.onSizeChange}
                        onAddToCart={this.props.onAddToCart}
                    />
                ))}
            </div>
        );
    }
}

export default Gallery;
