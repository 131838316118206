import React from "react";
import "../scss/Button.scss";

export default function Button(props) {
    let className = "button";
    if (props.selected) {
        className += " selected";
    }
    if (props.className) {
        className += ` ${props.className}`;
    }
    return (
        <button type="button" className={className} onClick={props.onClick}>
            {props.title}
        </button>
    );
}
