import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";

import firebase from "firebase/app";
import "firebase/auth";

// Initialize Firebase
const config = {
    apiKey: "AIzaSyAeRD4ISgiNrpHk_vjwIFrrwZdkoi8MR84",
    authDomain: "kils-oks-klader.firebaseapp.com",
    databaseURL: "https://kils-oks-klader.firebaseio.com",
    projectId: "kils-oks-klader",
    messagingSenderId: "471974339658"
};
firebase.initializeApp(config);

firebase.auth().onAuthStateChanged(user => {
    if (user) {
        const root = document.getElementById("root");
        //const isAdmin = user.uid === "39QixRLgM1RHabpO76yvF3MqI6c2";
        const isAdmin = user.uid === "xAl9vG0wSUeF5UUG9IFhOYmLOPc2";
        if (isAdmin) {
            root.classList.add("admin");
        }
        ReactDOM.render(<App isAdmin={isAdmin} />, root);
    } else {
        window.location.href = "/logga-in";
    }
});
