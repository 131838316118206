import React, { Component } from "react";
import "../scss/CartButton.scss";

class CartButton extends Component {
    render() {
        let className = "cart-button";
        if (this.props.translate) {
            className += " translate";
        }
        if (this.props.className) {
            className += ` ${this.props.className}`;
        }
        className += ` ${this.props.collapsed ? "cart-icon" : "close-icon"}`;
        return (
            <button
                className={className}
                id="openCartButton"
                onClick={this.props.onClick}
            />
        );
    }
}

export default CartButton;
