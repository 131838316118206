import React, { Component } from "react";
import Collapsible from "react-collapsible";
import GarmentOrder from "./GarmentOrder";
import Button from "./Button";

import swal from "sweetalert";
import fileDownload from "js-file-download";

import "../scss/Orders.scss";

import FirebaseManager from "../FirebaseManager";
import clothes from "../resources/clothes";

class Orders extends Component {
    constructor(props) {
        super(props);
        this.firebase = new FirebaseManager();
        this.state = {
            currentOrders: {},
            sentOrders: []
        };
    }

    componentDidMount() {
        this._fetchOrders();
    }

    _fetchOrders() {
        this.firebase
            .fetchOrders()
            .then(currentOrders => {
                this.setState({ currentOrders });
            })
            .catch(error => {
                console.error("Error fetching orders", error);
                swal({
                    title: "Hoppsan",
                    text: error.toLocaleString(),
                    icon: "warning"
                });
            });
        this.firebase
            .fetchSentOrders()
            .then(sentOrders => {
                this.setState({ sentOrders });
            })
            .catch(error => {
                console.error("Error fetching sent orders", error);
                swal({
                    title: "Hoppsan",
                    text: error.toLocaleString(),
                    icon: "warning"
                });
            });
    }

    _exportCurrentOrders() {
        const selected = Object.keys(this.state.currentOrders).filter(key => this.state.currentOrders[key].selected);
        if (selected.length === 0) {
            return;
        }
        const counts = {};
        for (const garment of selected) {
            for (const order of this.state.currentOrders[garment]) {
                counts[garment] = counts[garment] || {};
                for (const size of Object.keys(order.sizes)) {
                    counts[garment][size] = counts[garment][size] || 0;
                    counts[garment][size] += order.sizes[size];
                }
            }
        }
        let csv = "Plagg;Namn;Storlek;Antal\n";
        for (const garment of Object.keys(counts)) {
            for (const size of Object.keys(counts[garment])) {
                csv += `${garment};${clothes.find(c => c.name === garment).realName};${size};${
                    counts[garment][size]
                }\n`;
            }
        }
        fileDownload(csv, "clothes.csv");
    }

    _handleOrderSelect(name, selected) {
        console.log(name, selected);
        const currentOrders = this.state.currentOrders;
        currentOrders[name].selected = selected;
        this.setState({ currentOrders });
    }

    _markCurrentOrdersAsSent() {
        const selected = Object.keys(this.state.currentOrders).filter(key => this.state.currentOrders[key].selected);
        if (selected.length === 0) {
            console.log("No orders selected. Ignoring.");
            return;
        }
        const toSend = {};
        for (const key of selected) {
            toSend[key] = this.state.currentOrders[key];
        }
        this.firebase
            .markAsSent(toSend)
            .then(() => {
                console.log("Success");
                this._fetchOrders();
            })
            .catch(error => {
                console.error(error);
                swal({
                    title: "Hoppsan",
                    text: error.toLocaleString(),
                    icon: "warning"
                });
            });
    }

    render() {
        return (
            <React.Fragment>
                <div className="adminNav">
                    <Button
                        title="Exportera markerade plagg"
                        className="adminButton"
                        onClick={this._exportCurrentOrders.bind(this)}
                    />
                    <Button
                        title="Jag har skickat markerade plagg till SIGN"
                        className="adminButton"
                        onClick={this._markCurrentOrdersAsSent.bind(this)}
                    />
                    <div className="signOut admin">
                        <button type="button" onClick={() => this.firebase.signOut()}>
                            Logga ut
                        </button>
                    </div>
                </div>
                <div className="orderedCont">
                    <h1>Lagda beställningar</h1>
                    <div className="signDiv">
                        {this.state.sentOrders.map(order => (
                            <Collapsible
                                key={order.date}
                                trigger={<h2>Skickat till SIGN {new Date(order.date).toISOString().slice(0, 10)}</h2>}
                            >
                                {Object.keys(order.garments).map(key => {
                                    return <GarmentOrder key={key} name={key} orders={order.garments[key]} />;
                                })}
                            </Collapsible>
                        ))}
                    </div>
                    <div className="currentDiv">
                        {Object.values(this.state.currentOrders).length > 0 && (
                            <Collapsible trigger={<h2>Aktuella beställningar</h2>}>
                                {Object.keys(this.state.currentOrders).map(key => (
                                    <GarmentOrder
                                        key={key}
                                        name={key}
                                        orders={this.state.currentOrders[key]}
                                        onSelectedChange={this._handleOrderSelect.bind(this)}
                                    />
                                ))}
                            </Collapsible>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Orders;
