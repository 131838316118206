import React, { Component } from "react";
import "../scss/Garment.scss";

class Garment extends Component {
    render() {
        const item = this.props.item;
        return (
            <div className="singleGallery">
                <div className="topGallery">
                    <div className="galleryHeader">
                        <div>
                            <h2>{item.name}</h2>
							<p>{item.realName}</p>
                            <h3>
                                {item.price}
                                kr
                            </h3>
                        </div>
                        <div className="countContainer">
                            <p>BESTÄLLDA</p>
                            <p className="count">
                                {item.ordered}
                                st
                            </p>
                        </div>
                    </div>
                    <img src={item.imageURL} alt={item.name} />
                </div>
                <div className="bottomGallery">
                    <select
                        value={item.selectedSize}
                        onChange={event =>
                            this.props.onSizeChange(item, event.target.value)
                        }
                    >
                        {item.sizes.map(s => (
                            <option value={s} key={s}>
                                {s}
                            </option>
                        ))}
                    </select>
                    <button
                        type="button"
                        onClick={() => this.props.onAddToCart(item)}
                    >
                        Lägg till
                    </button>
                </div>
            </div>
        );
    }
}

export default Garment;
